import * as React from 'react';
import clsx from 'clsx';
import Seo from '../seo';
import BlogPost from './content-type/BlogPost';

export default function StoryBlokContentType({ data }) {
  const {
    content,
    lang,
    translated_slugs: translatedSlugs,
    name,
  } = data || {};

  const {
    component,
    seo_title: _seoTitle,
    seo_description: _seoDescription,
    remove_y_padding: removeYPadding,
    remove_x_padding: removeXPadding,
  } = content || {};

  const Components = {
    blogpost: BlogPost,
  };

  const Component = (component && Components[component]) || 'div';

  const translatedSlug = translatedSlugs.find((_translatedSlug) => _translatedSlug.lang === lang);
  const translatedTitle = translatedSlug ? translatedSlug.name : name;

  const seoDescription = _seoDescription;
  const seoTitle = _seoTitle || translatedTitle;

  const styles = clsx(
    'flex flex-col',
    !removeYPadding && 'pb-[100px] pt-20 lg:py-36 lg:pb-36',
    !removeXPadding && 'px-4 lg:px-16',
  );

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} />
      <div className={styles}>
        <Component data={data} title={translatedTitle} />
      </div>
    </>
  );
}
