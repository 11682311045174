/* eslint-disable react/no-unstable-nested-components */
import * as React from 'react';
import { Link } from 'gatsby';

import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { storyblokEditable, useStoryblokState } from 'gatsby-source-storyblok';
import { render, NODE_HEADING } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx';
import { slugify } from '../../../lib/utils';
import * as icons from '../../../icons/icon-pack';
import {
  BlogPostHighlightBox,
  BlogPostTableOfContents,
  BlogPostTable,
  BlogPostMediaImage,
  BlogPostMediaVideo,
  StoryblokImage,
  QuoteWithImage,
  CtaButton,
} from '..';
import Seo from '../../seo';

function BlogPost({ data, location }) {
  library.add({ ...icons });

  const story = useStoryblokState(data);
  const storyblokPreviewProps = storyblokEditable(story);

  const PublishedDate = ({ first_published_at: firstPublishedAt }) => {
    const _firstPublishedAt = new Date(firstPublishedAt);
    return _firstPublishedAt.toLocaleDateString('de-DE', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const image = story?.content?.hero;
  const storyName = story.translated_slugs
    .filter((slug) => slug.lang === story.lang)[0]?.name || story.name;

  return (
    <>
      <Seo
        title={story.content.seo_title}
        description={story.content.seo_description || ''}
      />
      <article
        itemScope
        itemType="http://schema.org/Article"
        className="blog-post w-full"
        {...storyblokPreviewProps}
      >
        { image
          && (
          <div className="pt-20 lg:pt-36 px-4 lg:px-16 pb-8">
            <StoryblokImage
              alt={story.name}
              src={image}
              ratios={{ ratio: '4:3', ratioSm: '16:9' }}
            />
          </div>
          )}
        <div className="max-w-full md:max-w-[80%] px-4 lg:px-16 mx-auto prose dark:prose-invert">
          <div className="flex md:block flex-col mb-[100px]">
            <div className="inline-flex justify-start items-center gap-2">
              <FontAwesomeIcon icon={icon(icons.faClock)} className="text-latori-green" />
              <span><PublishedDate first_published_at={story?.first_published_at} /></span>
            </div>
            {Array.isArray(story.tag_list) && story.tag_list.length > 0 && (
              <div className="md:pl-4 inline-flex justify-start items-center gap-2">
                <FontAwesomeIcon icon={icon(icons.faFolderClosed)} className="text-latori-green" />
                {story.tag_list.map((tag, index) => (
                  <span key={tag}>
                    <Link to={`/blog/${slugify(tag)}`}>{tag}</Link>
                    {index < story.tag_list.length - 1 ? ',' : ''}
                  </span>
                ))}
              </div>
            )}
            {story?.content?.author && (
              <div className="md:pl-4 inline-flex justify-start items-center gap-2">
                <FontAwesomeIcon icon={icon(icons.faUser)} className="text-latori-green" />
                <span>{story?.content?.author || ''}</span>
              </div>
            )}
          </div>
          <header>
            <h1 itemProp="headline">{storyName}</h1>
          </header>
          <section
            itemProp="articleBody"
            {...storyblokEditable(story.content)}
            className="blog-post-content"
          >
            {render(story.content.content, {
              nodeResolvers: {
                [NODE_HEADING]: (children, props) => {
                  const { level } = props;
                  const Heading = `h${level}`;
                  const hStyles = {
                    3: '',
                    4: '',
                  };
                  const styles = clsx(
                    'scroll-mt-24', // see https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far/56391657#answer-67923821
                    hStyles[level],
                  );
                  let id = null;
                  if (children) {
                    const [firstChild] = children;
                    if (typeof firstChild === 'string') {
                      id = slugify(firstChild);
                    } else if (firstChild.props) {
                      id = slugify(firstChild.props.children);
                    }
                  }
                  return (
                    <Heading id={id} className={styles}>{children}</Heading>
                  );
                },
              },
              blokResolvers: {
                /* eslint-disable no-useless-computed-key */
                ['blogpost-highlight-box']: (props) => <BlogPostHighlightBox {...props} />,
                ['blogpost-table-of-contents']: (props) => {
                  const listOfHeading = story
                    ?.content
                    ?.content
                    ?.content
                    .filter((k) => k.type === 'heading')
                    .filter((l) => l.attrs.level === 2)
                    .map((m) => m.content.filter((n) => n.type === 'text'))
                    .map((o) => o[0]);
                  return (
                    <BlogPostTableOfContents
                      {...props}
                      headings={listOfHeading || []}
                      location={location}
                    />
                  );
                },
                ['blogpost-table']: (props) => <BlogPostTable {...props} />,
                ['blogpost-media-image']: (props) => <BlogPostMediaImage {...props} articleTitle={story.name} />,
                ['blogpost-media-video']: (props) => <BlogPostMediaVideo {...props} />,
                ['quote-with-image']: (props) => <QuoteWithImage {...props} />,
                ['cta-button']: (props) => <CtaButton {...props} />,
                /* eslint-enable no-useless-computed-key */
              },
            })}
          </section>
          <div className="pb-24 pt-12">
            <div className="pt-12">
              <p>Teilen:</p>
              <div className="flex gap-4 flex-cols pt-2">
                <Link
                  to={`https://facebook.com/sharer.php?u=${typeof window !== 'undefined' && window.location.href}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-facebook"
                    size="lg"
                  />
                </Link>
                <Link
                  to={`https://twitter.com/intent/tweet?text=${typeof document !== 'undefined' && escape(document.title)}&url=${typeof window !== 'undefined' && window.location.href}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-twitter"
                    size="lg"
                  />
                </Link>
                <Link
                  to={`https://www.linkedin.com/shareArticle?mini=true&url=${typeof window !== 'undefined' && window.location.href}&title=${typeof document !== 'undefined' && escape(document.title)}&source=Latori`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-linkedin"
                    size="lg"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default BlogPost;
