/**
 * https://www.storyblok.com/faq/deploy-the-editor-on-netlify-without-rebuilding-gatsby
 * https://www.storyblok.com/faq/how-to-verify-the-preview-query-parameters-of-the-visual-editor
 */
import * as React from 'react';
import LayoutConsumer from '../components/layout-context';
import StoryBlokContentType from '../components/storyblok/StoryBlokContentType';

function Editor({ serverData }) {
  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'Editor', fullWidth: true });
        return <StoryBlokContentType data={serverData} />;
      }}
    </LayoutConsumer>
  );
}

export default Editor;

export async function getServerData({ headers }) {
  try {
    const requestUrl = new URL(headers.get('referer'));
    let path = requestUrl.searchParams.get('path');
    path = path === '' ? 'home' : path;

    const res = await fetch('https://gapi.storyblok.com/v1/api', {
      method: 'POST',
      headers: {
        Token: 't9DFrGNLE0hGttjZo4UfhQtt',
        Version: 'draft',
      },
      body: JSON.stringify({
        query: `
          query Item(
            $id: ID!
          ) {
            ContentNode(id: $id, resolve_relations: "references_tiles.references") {
              name
              uuid
              id
              lang
              first_published_at
              tag_list
              translated_slugs {
                lang
                name
                path
              }
              content
            }
          }
        `,
        variables: {
          id: path,
        },
      }),
    });

    if (!res.ok) {
      throw new Error('Response failed');
    }

    const { data } = await res.json();

    data.ContentNode.preview = requestUrl.searchParams.has('_storyblok');
    data.ContentNode.internalId = data.ContentNode.id;

    return {
      props: data.ContentNode,
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
